import * as React from "react"
import { Row, Col } from 'react-bootstrap'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import left from '../images/left.png'
import right from '../images/right.png'

const ArrowLeft = ({ onClick, ...rest }) => {
    const {
        onMove,
        carouselState: { currentSlide, deviceType }
    } = rest;
    return (
        <img src={left} alt="Arrow Left" className="left-arrow" onClick={() => onClick()} />
    )
}
const RightArrow = ({ onClick, ...rest }) => {
    const {
        onMove,
        carouselState: { currentSlide, deviceType }
    } = rest;
    return (
        <img src={right} alt="Arrow Right" className="right-arrow" onClick={() => onClick()} />
    )
}

function MarcasSlide({ mobile, data }) {

    const image = getImage(data.line.img)

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (

        <div className="m-auto pt-5 bg-black pb-5">
            <div className={mobile ? "m-4 center" : "m-5 center"}>
                <p className="mSemiBold white title-medium letter-sp-10 text-uppercase">{data.title}</p>
                <div>
                    <GatsbyImage image={image}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt={data.line.alt}
                        className="line-orange"
                    />
                </div>
            </div>
            <div className="width-80 m-auto" style={{ backgroundColor: "white !important" }}>
                {!mobile ?
                    <Carousel swipeable={false}
                        containerClass="carousel-container"
                        draggable={true}
                        showDots={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={!mobile ? true : false}
                        autoPlaySpeed={1500}
                        transitionDuration={500}
                        customRightArrow={<RightArrow />}
                        customLeftArrow={<ArrowLeft />}
                    >
                        {data.marcas.map((item, k) => (
                            <div key={"marca" + k} className="slide-marcas">
                                <img src={item.img} alt={item.alt} className="m-auto center width-60" />
                            </div>


                        ))}
                    </Carousel>
                    :
                    <Carousel swipeable={false}
                        containerClass="carousel-container"
                        draggable={true}
                        showDots={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={!mobile ? true : false}
                        autoPlaySpeed={1500}
                        transitionDuration={500}
                        swipeable={true}
                        arrows={false}
                    >
                        {data.marcas.map((item, k) => (
                            <div key={"marca" + k} className="slide-marcas">
                                <img src={item.img} alt={item.alt} className="m-auto center width-60" />
                            </div>


                        ))}
                    </Carousel>
                }


            </div>
        </div>
    );
}

export default MarcasSlide;