import * as React from "react"
import { Row, Col } from 'react-bootstrap'

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import $ from 'jquery/dist/jquery.slim' // importing this worked like a charm


function WeMakeItSimple({ img, alt, right, breakpoints }) {

    const mobile = breakpoints.mobile
    const image = getImage(img)
    if (typeof window !== "undefined") {
        $('#maindiv').width($('#div1').width());
    }
    return (

        <Row className={mobile ? "width-80 m-auto pt-5 bb-white " : "max-width m-auto pt-5 bb-white"}>
            <Col lg="6" md="6" sm="12" className="no-space-col-left pe-3">
                <GatsbyImage image={image}
                    quality={100}
                    formats={["auto", "webp", "avif"]}
                    alt={alt}
                    className={mobile ? "img-left-top bb-white center mb-5 max-width object-cover" : "img-left-top  center max-width object-cover"}
                />

            </Col>
            <Col lg="6" md="6" sm="12" className="no-space-col-left">
                <div className="text-right-top ms-1">
                    <div>
                        <p className="mBoldItalic title-large white mb-0 pb-0">{right.title}</p>
                    </div>
                    <div className="max-width mb-4">
                        <div id="maindiv">

                            <div id="div1" className="mMedium title-cut-text orange line-height-2rem">
                                &nbsp;for pressing, bottling, labeling, coding, packaging, palletizing,
                            </div>
                            <div id="div2" className="mMedium title-cut-text orange line-height-2rem">
                                for pressing, bottling, labeling, coding, packaging, palletizing,
                            </div>
                        </div>


                    </div>

                    {right.details.map((item, i) => (
                        <div key={"text" + i} className="width-80">
                            <p className="mMedium details-medium white" style={{marginBottom: "10px"}}>{item}</p>
                        </div>
                    ))}

                </div>
            </Col>
        </Row >
    );
}

export default WeMakeItSimple;